import { graphql } from "gatsby"
import React from "react"
import ItemDetails from "../components/ItemDetails"
import Layout from "../components/layout"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!, $id: ID) {
    detailsItem: gcms {
        labTutorial(locales: $locale, where: { id: $id }) {
        title
        longDescription{
          html
        }
        sliderImages(locales: en) {
          url
        }
        tutorial_document(locales: en) {
          url
        }
      }
    }
  }
`
// download tutorial path not clear on graphcms
const LabTutorialsDetails = ({ t, data }) => {
  return (
    <Layout>
      <ItemDetails t={t} detailItem={data.detailsItem.labTutorial} detailsType="tutorials"/>
    </Layout>
  )
}

export default LabTutorialsDetails
